import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://62301824270675e0f91dbf05e7734e01@o1168935.ingest.us.sentry.io/4508011424710656",
  project: "wildatheart-astro",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
